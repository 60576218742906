import i18n from '../locales/i18n';
import jaJP from 'antd/locale/ja_JP';
import enUS from 'antd/locale/en_US';
import '../styles/index.css';
import React, { useEffect, useState } from 'react';
import { ConfigProvider, App as AppAntd, Modal, Button } from 'antd';
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession } from '@aws-amplify/auth';
import {
  Loading,
  SiderMenuLayout,
  Header,
  Menu,
  SelectLang,
  ThemeSettings,
  APIService,
  registerAmplify,
  SignIn,
  themeList,
  ChatUIBackend,
} from '@meteor/frontend-core';
import { AppContext } from '../contexts/AppContext';
import useStorage from '../hooks/useStorage';
import { defaultTheme } from '../consts/default-configuration';
import { useNavigate, useLocation, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import ProjectPage from './project';
import ProjectPlan from './plan';
import PlanOptionPage from './plan-option';
import PlanOptionDetailPage from './plan-option-detail';
import aipskdRoute from '../routes/index';
import { LogoutOutlined, SettingOutlined, ProjectOutlined } from '@ant-design/icons';
import { chatMessageConfig, messageMapping } from '../consts/chat-message';
import { CHAT_KEYWORDS } from '../consts/chat-keywords';
import useBusinessProp from '../hooks/useBusinessProp';
import OptimizationJob from '../components/optimization-job';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import mockList from '../http/APIMock';
import useRoleAuth from '../hooks/useRoleAuth';
import UserSettings from '../components/user-settings';
import ProjectListPage from '../components/project-list';
dayjs.locale('ja');

export const PREFIXCLS = 'effimate';

registerAmplify(process.env);

APIService.mockApiList = mockList;
APIService.env = process.env;

const App: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { getItem, setItem } = useStorage();
  const [authInfo, setAuthInfo] = useState<any>({
    disableAuth: process.env.REACT_APP_DISABLE_AUTH === 'true',
    loading: process.env.REACT_APP_DISABLE_AUTH !== 'true',
    user: null,
    loggedOut: true,
    signOut: async () => {
      await signOut();
      setAuthInfo({ ...authInfo, user: null });
      await navigate('/auth/login', { replace: true });
    },
  });

  const chartThemeName =
    process.env.REACT_APP_CHART_THEME && Object.keys(themeList).includes(process.env.REACT_APP_CHART_THEME)
      ? process.env.REACT_APP_CHART_THEME
      : 'accenture';

  themeList[chartThemeName] = {
    color: ['#1A4F99', '#3299D9', '#9FD9F6', '#093B5E', '#619BC5', '#195D87', '#B0CEDB', '#507FA2'],
  };

  const themeName = process.env.REACT_APP_THEME && defaultTheme[process.env.REACT_APP_THEME] ? process.env.REACT_APP_THEME : 'accenture';
  const [globalEvent, setGlobalEvent] = useState<any>(null);
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
  const [color, setColor] = useState<any>();
  const [configuration, setConfiguration] = useState<any>(defaultTheme[themeName].configuration);
  const [echartTheme, setEchartTheme] = useState<any>(chartThemeName);
  const [customEchartTheme, setCustomEchartTheme] = useState<any>({});
  const [scrollCount, setScrollCount] = useState(0);
  const [chartSelectors, setChartSelectors] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [contextLoading, setContextLoading] = useState<boolean>(false);

  const selectedLang = getItem('language', 'local') || 'ja';

  const [locale, setLocale] = useState(selectedLang === 'ja' ? jaJP : enUS);

  const [projectListModal, setProjectListModal] = useState<boolean>(false);

  const setDefaultConfiguration = () => {
    const list: any = {};
    list[themeName] = {
      configuration: defaultTheme[themeName].configuration,
      colors: defaultTheme[themeName].color,
    };
    setItem('currentTheme', themeName, 'local');
    setItem('themeList', JSON.stringify(list), 'local');
  };

  const businessProp = useBusinessProp({ color, globalEvent, authInfo });

  const { checkRoleFunctionAuth } = useRoleAuth({ authentication: businessProp.authentication });
  const [showUserSettings, setShowUserSettings] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
    let settingsColor: any = null;
    const fontFamily = getItem('fontFamily', 'local');
    if (fontFamily) {
      document.body.style.fontFamily = fontFamily;
    } else {
      setItem(
        'fontFamily',
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        'local'
      );
    }
    const customEchartThemeLocal = getItem('customEchartTheme', 'local');
    if (customEchartThemeLocal && process.env.REACT_APP_FORCE_SETTINGS !== 'true') {
      setCustomEchartTheme(JSON.parse(customEchartThemeLocal));
    }

    const currentEchartTheme = getItem('echartTheme', 'local');
    if (currentEchartTheme && process.env.REACT_APP_FORCE_SETTINGS !== 'true') {
      setEchartTheme(currentEchartTheme);
    }
    const currentTheme = getItem('currentTheme', 'local');
    if (currentTheme) {
      const themeString = getItem('themeList', 'local');
      if (themeString) {
        const themeInfo = JSON.parse(themeString);
        if (themeInfo[currentTheme]) {
          const themeConfiguration = themeInfo[currentTheme].configuration;
          if (themeConfiguration) {
            setConfiguration(themeConfiguration);
            const themeColors = themeInfo[currentTheme].colors;
            if (themeColors) {
              const themeColor = themeColors[themeConfiguration.theme];
              if (themeColor) {
                settingsColor = themeColor;
              }
            }
          }
        }
      }
    }
    if (!settingsColor || process.env.REACT_APP_FORCE_SETTINGS === 'true') {
      setDefaultConfiguration();
      const { theme } = defaultTheme[themeName].configuration;
      settingsColor = defaultTheme[themeName].color[theme];
    }
    setColor(settingsColor);
  }, []);

  useEffect(() => {
    if (!authInfo.disableAuth && !authInfo.user && location.pathname !== '/auth/login' && location.pathname !== '/aipskd') {
      getCurrentUser()
        .then(async (user) => {
          const tokens = (await fetchAuthSession({ forceRefresh: true })).tokens;
          const token = tokens.idToken.toString();
          APIService.baseConfig.headers = { Authorization: `Bearer ${token}` };
          const attributes = await fetchUserAttributes();
          setAuthInfo({
            ...authInfo,
            loading: false,
            loggedOut: false,
            user: { ...user, attributes },
          });
        })
        .catch((error: any) => {
          setAuthInfo({
            ...authInfo,
            loading: false,
            loggedOut: error && error === 'The user is not authenticated',
          });
          navigate('/auth/login');
        });
    } else if (location.pathname === '/auth/login') {
      setAuthInfo({
        ...authInfo,
        loading: false,
      });
    }
  }, [location]);

  return (
    <div className="aipskd">
      <AppAntd>
        {color ? (
          <AppContext.Provider
            value={{
              authInfo,
              setAuthInfo,
              contextLoading,
              setContextLoading,
              color,
              setColor,
              locale,
              setLocale,
              configuration,
              setConfiguration,
              echartTheme,
              setEchartTheme,
              customEchartTheme,
              setCustomEchartTheme,
              menuCollapsed,
              setMenuCollapsed,
              globalEvent,
              setGlobalEvent,
              scrollCount,
              setScrollCount,
              chartSelectors,
              setChartSelectors,
              ...businessProp,
            }}
          >
            <ConfigProvider
              locale={locale}
              prefixCls={PREFIXCLS}
              theme={{
                token: {
                  colorPrimary: color.primaryColor,
                  colorSuccess: color.successColor,
                  colorWarning: color.warningColor,
                  colorError: color.errorColor,
                  colorInfo: color.infoColor,
                  colorLink: color.primaryColor,
                  borderRadius: 4,
                },
              }}
            >
              {authInfo.loading ? (
                <Loading />
              ) : (
                <>
                  <Routes>
                    <Route
                      path="/auth/login"
                      element={
                        <SignIn
                          redirect={!!process.env.REACT_APP_LOGIN_ONLINE_UI}
                          federatedIdentifyProvider={process.env.REACT_APP_FEDERATED_IDENTIFY_PROVIDER}
                          backgroundImageUrl={`${process.env.REACT_APP_DOMAIN}/assets/backgroundpic.jpg`}
                        />
                      }
                    />
                    <Route
                      path="/aipskd"
                      element={
                        authInfo.user ? (
                          // <Main />
                          <SiderMenuLayout
                            header={
                              <Header
                                moduleInfo={aipskdRoute}
                                userInfo={authInfo}
                                color={color}
                                otherOperations={[
                                  <SelectLang i18n={i18n} color={color} setLocale={setLocale} />,
                                  <ChatUIBackend
                                    configuration={{
                                      avatar: process.env.REACT_APP_DOMAIN + '/assets/counsel-gpt.png',
                                    }}
                                    onChatEvent={setGlobalEvent}
                                    CHAT_KEYWORDS={CHAT_KEYWORDS}
                                    chatMessageConfig={chatMessageConfig}
                                    messageMapping={messageMapping}
                                    openaiConfig={process.env}
                                    color={color}
                                  />,
                                  <OptimizationJob />,
                                  <ThemeSettings
                                    color={color}
                                    setColor={setColor}
                                    configuration={configuration}
                                    setConfiguration={setConfiguration}
                                    echartTheme={echartTheme}
                                    setEchartTheme={setEchartTheme}
                                    customEchartTheme={customEchartTheme}
                                    setCustomEchartTheme={setCustomEchartTheme}
                                    antdTheme={themeName}
                                    defaultTheme={defaultTheme}
                                    registerList={Object.keys(themeList)}
                                    showSettings={showSettings}
                                    setShowSettings={setShowSettings}
                                  />,
                                ]}
                                userOperations={[
                                  {
                                    key: 'config',
                                    label: (
                                      <span>
                                        <SettingOutlined />
                                        <span style={{ padding: 8 }}>Settings</span>
                                      </span>
                                    ),
                                    onSelect: () => {
                                      setShowSettings(true);
                                    },
                                  },
                                  {
                                    key: 'logout',
                                    label: (
                                      <span>
                                        <LogoutOutlined />
                                        <span style={{ padding: 8 }}>Logout</span>
                                      </span>
                                    ),
                                    onSelect: async () => {
                                      await authInfo.signOut();
                                    },
                                  },
                                ]}
                              />
                            }
                            menu={
                              <Menu
                                selectedMenuKey={'project'}
                                menuItems={aipskdRoute.routes.map((route) => ({
                                  ...route,
                                  label: route.i18n ? t(route.i18n) : route.label,
                                }))}
                                color={color}
                                menuCollapsed={menuCollapsed}
                              />
                            }
                            content={<Outlet></Outlet>}
                            contentLoading={contextLoading}
                            color={color}
                            moduleConfig={aipskdRoute}
                            collapsed={menuCollapsed}
                            onCollapsed={setMenuCollapsed}
                          />
                        ) : (
                          <Loading />
                        )
                      }
                    >
                      <Route path="/aipskd/project" element={<ProjectPage />} />
                      <Route path="/aipskd/plan" element={<ProjectPlan />} />
                      <Route path="/aipskd/plan-option" element={<PlanOptionPage />} />
                      <Route path="/aipskd/plan-option-detail" element={<PlanOptionDetailPage />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/aipskd/project" replace />} />
                  </Routes>
                </>
              )}
            </ConfigProvider>
          </AppContext.Provider>
        ) : (
          <Loading />
        )}
      </AppAntd>
    </div>
  );
};

export default App;
