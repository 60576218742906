import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ACTION_EVENT } from '../consts/action-event';
import APIList from '../http/ApiList';
import useRoleAuth, { AUTH_OPEN_STATE_TYPE } from './useRoleAuth';
import _, { startsWith, endsWith } from 'lodash';

const useBusinessProp = ({ color, globalEvent, authInfo }) => {
  const [authentication, setAuthentication] = useState<any>();
  const { AUTH_OPEN_STATE } = useRoleAuth({ authentication });
  const APP_GROUPS_REGEX = /^EFFIMATE_(.+)_(.+)$/;
  const APP_GROUPS_ADMIN = 'EFFIMATE_ADMIN';

  // data switcher
  const [projectId, setProjectId] = useState<string>();
  const [planId, setPlanId] = useState<string>();
  const [optimizeId, setOptimizeId] = useState<string[]>([]);
  const [optimizationId, setOptimizationId] = useState<string[]>([]);
  const [optimizationIds, setOptimizationIds] = useState<string[]>([]);
  const [disciplineIds, setDisciplineIds] = useState<string[]>([]);
  const [cwaIds, setCwaIds] = useState<string[]>([]);
  const [projectList, setProjectList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [optimizeList, setOptimizeList] = useState([]);
  const [optimizationList, setOptimizationList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [cwaList, setCwaList] = useState([]);
  const [dataSection, setDataSection] = useState<{
    projectId: string;
    planId: string;
    optimizeId: string;
    optimizationId: string;
    optimizeIds: string[];
    optimizationIds: string[];
  }>();
  const [selectOptions, setSelectOptions] = useState<{
    projects: any[];
    plans: any[];
    optimizes: any[];
    optimizations: any[];
  }>();

  // alert
  const importanceCategoryMap = {
    H: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.errorColor}
      >
        H
      </Tag>
    ),
    M: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.warningColor}
      >
        M
      </Tag>
    ),
    L: (
      <Tag
        style={{
          width: 24,
          textAlign: 'center',
          height: 16,
          lineHeight: '14px',
          padding: 0,
        }}
        color={color?.successColor}
      >
        L
      </Tag>
    ),
  };

  // memo
  const [memo, setMemo] = useState();
  const [memoSwitch, setMemoSwitch] = useState(false);
  const [memoSearchValue, setMemoSearchValue] = useState();
  const [memoLoading, setMemoLoading] = useState(false);

  useEffect(() => {
    getAuthentication();
  }, [authInfo.user]);

  useEffect(() => {
    if (globalEvent && globalEvent.eventType === ACTION_EVENT.AUTH_UPDATE) {
      getAuthentication();
      globalEvent.eventType = null;
    }
  }, [globalEvent]);

  const getAuthentication = () => {
    if (!authInfo.user) {
      return;
    }
    // 刚进入画面时，创建用户
    APIList.getAuthentication().get();
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return;
    }

    const appGroupsStr = removeSquareBrackets(
      authInfo.user.attributes['custom:groups'] || authInfo.user.attributes['custom:app-groups'] || ''
    );
    const orgAppGroups = appGroupsStr ? appGroupsStr.split(',') : [];
    const regex = APP_GROUPS_REGEX;
    const appGroups = [];
    let pjtAdmin = false;
    orgAppGroups.forEach((appGroup) => {
      if (appGroup === APP_GROUPS_ADMIN) {
        pjtAdmin = true;
      } else {
        const [str, projectId, projectRole] = appGroup.trim().match(regex);
        appGroups.push({
          projectId,
          projectRole,
        });
      }
    });
    const systemRole =
      pjtAdmin || authInfo.user.attributes?.email?.includes('@accenture.com')
        ? // TODO
          //  || authInfo.user.attributes?.email?.includes('@accenture.com')
          'ADMIN'
        : 'USER';

    setAuthentication({
      systemRole,
      appGroups,
    });
  };

  const removeSquareBrackets = (str) => {
    let finalStr = str;
    if (startsWith(str, '[')) {
      finalStr = finalStr.slice(1);
    }
    if (endsWith(str, ']')) {
      finalStr = finalStr.slice(0, -1);
    }
    return finalStr;
  };

  /**
   * プロジェクトAdminの制御
   * @returns
   */
  const projectAdminAuth = (projectId) => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole, appGroups } = authentication;
    switch (systemRole) {
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        const projectRoleInfo = appGroups.find((role: any) => role.projectId === projectId);
        if (projectRoleInfo) {
          const { projectRole } = projectRoleInfo;
          switch (projectRole.toLocaleUpperCase()) {
            case 'MANAGER':
              return false;
            case 'EDITOR':
              return false;
            case 'VIEWER':
              return false;
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  };

  /**
   * プロジェクト立ち上げとクローズの制御
   * @returns
   */
  const projectManagerAuth = (projectId) => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole, appGroups } = authentication;
    switch (systemRole) {
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        const projectRoleInfo = appGroups.find((role: any) => role.projectId === projectId);
        if (projectRoleInfo) {
          const { projectRole } = projectRoleInfo;
          switch (projectRole.toLocaleUpperCase()) {
            case 'MANAGER':
              return true;
            case 'EDITOR':
              return false;
            case 'VIEWER':
              return false;
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  };

  /**
   * プロジェク改修の制御
   * @param projectId
   * @returns
   */
  const projectEditorAuth = (projectId) => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return true;
    }

    if (!authentication) {
      return false;
    }
    const { systemRole, appGroups } = authentication;
    switch (systemRole) {
      // サービス利用者で、プロジェクトを作成でき、全てのプロジェクトに対してデフォルトで`MANAGER` のプロジェクトロールを持つ
      case 'ADMIN':
        return true;
      // サービス利用者で、プロジェクトロールが付与されるまでログインが可能だが、その以外の権限を持っていない
      case 'USER':
        const projectRoleInfo = appGroups.find((role: any) => role.projectId === projectId);
        if (projectRoleInfo) {
          const { projectRole } = projectRoleInfo;
          switch (projectRole.toLocaleUpperCase()) {
            case 'MANAGER':
              return true;
            case 'EDITOR':
              return true;
            case 'VIEWER':
              return false;
            default:
              return false;
          }
        }
        return false;
      default:
        return false;
    }
  };

  return {
    authentication,
    projectAdminAuth,
    projectManagerAuth,
    projectEditorAuth,
    getAuthentication,
    projectId,
    setProjectId,
    planId,
    setPlanId,
    optimizeId,
    setOptimizeId,
    optimizationId,
    setOptimizationId,
    optimizationIds,
    setOptimizationIds,
    disciplineIds,
    setDisciplineIds,
    cwaIds,
    setCwaIds,
    projectList,
    setProjectList,
    planList,
    setPlanList,
    optimizeList,
    setOptimizeList,
    optimizationList,
    setOptimizationList,
    disciplineList,
    setDisciplineList,
    cwaList,
    setCwaList,
    dataSection,
    setDataSection,
    memo,
    setMemo,
    memoSwitch,
    setMemoSwitch,
    memoSearchValue,
    setMemoSearchValue,
    memoLoading,
    setMemoLoading,
    selectOptions,
    setSelectOptions,
    importanceCategoryMap,
  };
};

export default useBusinessProp;
